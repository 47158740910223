import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
// import { Delete, Edit, Visibility } from '@mui/icons-material';

import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';
import Iconify from '../iconify';
import CustomNoRowsOverlay from './noRowsOverlay';
import CustomLoadingOverlay from './loadingOverlay';
import CustomPagination from './customPagination';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../Notification/Snackbar';
import { translateThis } from '../../helpers/language.helper';
import AppPopOverMenu from '../appPopOverMenu';
import ConfirmDialog from '../dialogs/confirm.dialog';
import { getUniqueId } from '../../helpers/helper.functions';




// Create the data grid options object with columns and rowData properties set to our own values
const AppTable = (props) => {
    const {
        columns = [], // columns without actions id column
        dataURL = '',
        checkboxSelection = false,
        mobileRowRender = () => <></>,
        isRefreshData = false,
        onRefreshSuccess = () => null, // Callback to reset the parent state so that next refresh will happen
        actionColumnSettings = {},
        isDeleteActionEnabled = true,
        isEditActionEnabled = true,
        isViewActionEnabled = true,
        extraActions = () => null
    } = props;

    const initialData = {
        limit: 25,
        page: 1,
        result: [],
        totalCount: 0,
        totalPages: 0
    }

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [dataArr, setDataArr] = useState(initialData);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedId, setSelectedId] = useState('');

    /**
  the legendary use effect function
  This will run very first on render
   */
    useEffect(() => {
        getDataArr();
        if (isRefreshData) {
            onRefreshSuccess();
        }

        return () => {
            setDataArr(initialData)
        };
        // eslint-disable-next-line
    }, [isRefreshData]);


    // get resource from api
    const getDataArr = (limit = 25, page = 1) => {
        try {
            if (page > 0 && dataURL) {
                setIsLoading(true);
                const apiCaller = new ApiCaller(dataURL);
                apiCaller.getList({ limit, page })
                    .then(({ data }) => {
                        if (data?.totalCount && data?.result) {
                            // applyData(data);
                            setDataArr(data)
                        }
                    })
                    .catch(() => showSnackbar().failure('Something went wrong'))
                    .finally(() => {
                        setIsLoading(false);
                    });
            }

        } catch (error) {
            console.log(error)

        }
    }

    // const applyData = (data) => {
    //     let formattedResult = data.result ?? [];
    //     formattedResult = formattedResult.map((row) => {
    //         if (row.id) {
    //             return row;
    //         } if (row._id) {
    //             return { ...row, id: row._id };
    //         }
    //         return { ...row, id: getUniqueId('row-') }

    //     });
    //     setDataArr({...data, result:formattedResult })

    // }



    /**
   * Property delete
   */
    const deleteData = () => {
        if (selectedId) {
            const apiUrl = dataURL.split('adad')[0]; // omit query params
            const apiCaller = new ApiCaller(apiUrl);
            apiCaller.customPatchData(selectedId, { isDeleted: true })
                .then(() => showSnackbar().success('Resource deleted successfully'))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                    getDataArr(dataArr?.limit, dataArr?.page);
                })
        }

    }

    /**
     * On delete button click
     * @param {Object} row 
     */
    const onDeleteClick = (row) => {
        setOpenDeleteDialog(true);
        setSelectedId(row?.id);
    }


    /**
     * 
     * @returns JSX Element
     */
    function CustomToolbar() {
        return (
            <GridToolbarContainer className='app-grid-tool-bar'
                sx={{
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    minHeight: '100px',
                }}
            >
                <GridToolbarExport
                    printOptions={{
                        disableToolbarButton: true
                    }}
                />
                <GridToolbarQuickFilter
                    sx={{
                        borderRadius: 1,
                        paddingLeft: '14px',
                        paddingRight: '10px',
                        // backgroundColor: '#f0f0f0',
                        border: '1px solid rgba(145, 158, 171, 0.32)',
                        '&:hover': {
                            //   backgroundColor: '#e0e0e0',
                        },

                        '.MuiInput-underline:before': {
                            borderBottom: 'none', // Custom underline color (default state)
                        },
                        '.MuiInput-underline:hover:before': {
                            borderBottom: 'none', // Underline color on hover
                        },
                        '.MuiInput-underline:after': {
                            borderBottom: 'none', // Custom underline when focused
                        },


                    }}
                />
            </GridToolbarContainer>
        );
    }

    /**
     * Renders the column
     */
    const renderColumns = () => {
        const actionColumn = {
            field: 'actions',
            headerName: translateThis('Actions'),
            flex: 2,
            width: 135,
            minWidth: 135,
            maxWidth: 135,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            ...actionColumnSettings,
            renderCell: ({ row }) => (
                <>
                    {isViewActionEnabled ? <span><IconButton onClick={() => navigate(`view/${row.id}`)} children={<Iconify icon={'eva:eye-fill'} sx={{ mr: 0 }} />} /></span> : null}
                    {isEditActionEnabled ? <span><IconButton onClick={() => navigate(`edit/${row.id}`)} children={<Iconify icon={'eva:edit-2-fill'} sx={{ mr: 0 }} />} /></span> : null}
                    {isDeleteActionEnabled ? <span> <IconButton onClick={() => onDeleteClick(row)} children={<Iconify icon={'eva:trash-2-outline'} sx={{ mr: 0 }} />} /> </span> : null}
                    {typeof extraActions === 'function' ? <AppPopOverMenu options={extraActions(row)} /> : null}
                </>
            )
        }
        columns.push(actionColumn);
        const uniqueColumns = columns.filter((obj, index, self) => self.findIndex((o) => o.field === obj.field) === index);
        return uniqueColumns;

    }

    return (
        <>
            <Box className="PC-Table" sx={{ width: '100%' }}>
                <DataGrid
                    density='comfortable'
                    rows={dataArr.result || []}
                    autoHeight
                    loading={isLoading}
                    columns={renderColumns()}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[0, 10, 15, 25]}
                    checkboxSelection={checkboxSelection}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    pagination
                    slots={{
                        toolbar: CustomToolbar,
                        pagination: () => CustomPagination({ ...dataArr, onPageChange: getDataArr }),
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: CustomLoadingOverlay,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f4f6f8',
                        },
                    }}
                />
            </Box>

            <Box className="mobile-Table" style={{ display: 'none' }}>
                {/* <ul>
                    {dataArr.result.map((row, rowIndex) => (
                        <li key={rowIndex}>
                            {renderColumns().map((column, columnIndex) => {
                                if (!row) return null;
                                return (

                                    <div key={columnIndex}>
                                        {column.renderCell ? column.renderCell({ row }) : column.valueFormatter ? column.valueFormatter({ value: row[column.field] }) : <span>{row[column.field]}</span>}
                                    </div>
                                )
                            })}
                        </li>
                    ))}
                </ul> */}

                <div className='mob-table-wrp f-wrp'>
                    <div className='mob-table-search'>
                        <input className='mob-table-input' placeholder={translateThis('Search')} onChange={(e) => null} />
                    </div>
                    {dataArr?.result.length > 0 ? <>
                        <ul>
                            {
                                dataArr?.result.map((row, i) => <li key={i}>{mobileRowRender(row)}</li>)
                            }
                        </ul>

                    </> : isLoading ? <span>Loading...</span> : <span>No results found</span>}
                </div>




            </Box>

            {openDeleteDialog && <ConfirmDialog
                isOpen={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    setSelectedId('');
                }}
                title={`Confirm delete`}
                className={'delete-popup'}
                description={`Are you sure you want to delete this resource?\nThis action is permanent and cannot be undone.`}
                okayButtonText={'Delete'}
                onConfirm={deleteData}
            />}
        </>
    );
}

AppTable.propTypes = {
    isRefreshData: PropTypes.any,
    onRefreshSuccess: PropTypes.any,
    mobileRowRender: PropTypes.any,
    dataURL: PropTypes.string,
    columns: PropTypes.array,
    actionColumnSettings: PropTypes.object,
    checkboxSelection: PropTypes.bool,
    isDeleteActionEnabled: PropTypes.bool,
    isEditActionEnabled: PropTypes.bool,
    isViewActionEnabled: PropTypes.bool,
    extraActions: PropTypes.any
}

export default AppTable;