import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// @mui
import { Button, IconButton, TextField, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { showSnackbar } from '../../components/Notification/Snackbar';
import RichTextEditor from '../../components/rich-text-editor';
import SVGIcons from '../../assets/images/icons/svgIcons';

import IconPicker from '../../components/iconPicker/icon-picker';
import IconViewer from '../../components/iconPicker/icon-viewer';

import FileUploader from '../../components/awsS3ImageUploader/file.uploader';
import './index.scss';
import ApiCaller from '../../services/api/general';
import RichTextViewer from '../../components/rich-text-editor/TextViewer';
import S3ImageViewerWithFallback from '../../components/awsS3ImageViewer/ImgViewerWithFallback';
import { getUniqueId } from '../../helpers/helper.functions';
// ........................................................................




const AddCategory = () => {
    const apiCaller = new ApiCaller('category');

    const [categoryIcon, setCategoryIcon] = useState('');
    const [categoryTitle, setCategoryTitle] = useState('');
    const [categories, setCategories] = useState([])

    // const [serviceIcon, setServiceIcon] = useState([]);
    // const [service, setService] = useState([{
    //     id: getUniqueId(),
    //     title: '',
    //     icon: ''
    // }]);


    // // useEffect(() => {
    // //     localStorage.setItem('service', JSON.stringify(service));
    // // }, [service]);


    // const setCategory = (name, value) => {
    //     const formDataAs = { ...service };
    //     setService({ ...formDataAs, ...{ [name]: value } });
    //     return true;
    // }

    const handleSubmit = () => {
        const category = {
            name: categoryTitle,
            icon: categoryIcon
        }
        setCategories((prv) => [...prv, category]);
        setCategoryIcon('');
        setCategoryTitle('');

    };


    // useEffect(() => {
    //     getData();
    //     return () => {
    //         setTrustedPartnersImages({});
    //     };
    //     // eslint-disable-next-line
    // }, []);

    // const getData = () => {
    //     apiCaller.getData('/category')
    //         .then((data) => {
    //             console.log(data, 'data abc');
    //             if (data) {
    //                 setTrustedPartnersImages(data.optionValue);
    //             }
    //         })
    //         .catch(() => showSnackbar().failure('Something went wrong'))
    // }

    // const handleSubmit = () => {
    //     if (trustedPartnersImages.length > 0) {
    //         const postData = {
    //             partners: trustedPartnersImages,
    //         }
    //         const action = saveData;

    //         action(postData).then((data) => {
    //             if (data.success) {
    //                 showSnackbar().success('List Updated');
    //             } else {
    //                 showSnackbar().failure('Something went wrong');
    //             }
    //         })

    //     } else {
    //         showSnackbar().warning('Please select an image');
    //     }
    // }

    // const saveData = (postData) => apiCaller.customPostData('/add-trusted-partners', postData);

    // const deletePartnerImg = (fileKey) => setTrustedPartnersImages((prv) => prv.filter((img) => img !== fileKey))

    // const removeFile = () => {
    //     setServiceIcon('');
    // }


    return (
        <>
            <div className='category-list-wrp f-wrp' style={{ paddingBottom: '10px', paddingTop: '15px', minWidth: '500px' }}>

                <div className='category-add-wrp f-wrp'>

                    <div className='category-icon'>
                        {/* <div className='form-upload-sec icon-box f-wrp'>
                            <IconPicker onSelect={(icon) => setCategoryIcon(icon)} />
                        </div> */}
                        {!categoryIcon ? <div className='form-upload-sec icon-box f-wrp'>
                            <IconPicker onSelect={(icon) => setCategoryIcon(icon)} />
                        </div> : null}
                        {categoryIcon ? <div className='form-upload-view icon-box f-wrp'>
                            <button type='button' className='delete-btn' onClick={() => setCategoryIcon('')}><SVGIcons.CloseCrossIcon /></button>
                            <IconViewer icon={categoryIcon} />
                            {/* <IconPicker onSelect={(icon) => setCategoryIcon(icon)} /> */}
                        </div> : null}
                    </div>

                    <div className='each-TextField category-title f-wrp'>
                        <TextField
                            key={`art_create_form_Key_title`}
                            fullWidth
                            // label={('art_create_form_question_title')}
                            name='title'
                            // disabled={!isEditEnabled}
                            id='art_create_form_question_title'
                            value={categoryTitle}
                            type='text'
                            isRequired
                            placeholder='Title'
                            className='custom-textfield'
                            // helperText={isTouched && !formData.title ? 'Title is required' : ''}
                            // error={isTouched && !formData.title}
                            onChange={(e) => setCategoryTitle(e.target.value)}
                            useDefaultValidation
                        />
                    </div>

                    <div className='btn-wrp'>
                        <Button
                            onClick={handleSubmit}
                            fullWidth variant="contained" className='blu-btn'>
                            Add
                        </Button>
                    </div>

                </div>

                <div className='category-list f-wrp'>

                    {(categories.length > 0) ? <div className='category-preview'>
                        {categories.map((element, key) => (
                            <div className='each-img-box' key={key}>
                                <button type='button' className='delete-btn' onClick={() => null
                                    // deletePartnerImg(element)
                                }><SVGIcons.CloseCrossIcon /></button>
                                <IconViewer icon={element.icon} /> {element.name}
                            </div>
                        ))}

                    </div> : null}







                    {/* <div className='image-uploader'>
                            <FileUploader
                                key={'trusted-partners-images'}
                                uploaderKey={'trusted-partners-images'}
                                uploadCaption=''
                                onUploadEnd={(img) => setTrustedPartnersImages((prv) => [...prv, img.fileKey])}
                            />
                        </div>

                        {(trustedPartnersImages.length > 0) ? <div className='image-preview'>
                            {trustedPartnersImages.map((element, key) => (
                                <span className='each-img-box' key={key}>
                                    <button type='button' className='delete-btn' onClick={() => deletePartnerImg(element)}><SVGIcons.CloseCrossIcon /></button>
                                    <S3ImageViewerWithFallback key={`file-${element}`} fileKey={element} />
                                </span>
                            ))}

                        </div> : null} */}


                </div>
            </div>
        </>
    );
}

AddCategory.propTypes = {}

export default AddCategory;











