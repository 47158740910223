import React from 'react';
import PropTypes from 'prop-types';
import SVGIcons from '../../assets/images/icons/svgIcons';
import ConfirmDialog from '../dialogs/confirm.dialog';
import availableIcons from './available-icons';
import './iconPicker.scss';





const IconPicker = (props) => {
    const {
        isOpen = false,
        onSelect = () => null,
        onClose = () => null
    } = props;

    const [open, setOpen] = React.useState(isOpen);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedIcon, setSelectedIcon] = React.useState('');

    /**
     * Handles the close click
     */
    const handleClose = () => {
        setOpen(false);
        setIsLoading(false);
        if (typeof onClose === "function") {
            onClose(selectedIcon);
        }
    };

    /**
     * Handles the okay click
     */
    const handleConfirm = () => {
        if (typeof onSelect === "function") {
            setIsLoading(true);
            onSelect(selectedIcon);
        }
        setOpen(false)
    };
    return (
        <>
            <button key={selectedIcon} className={`selectIconButton ${selectedIcon ? 'active' : ''}`} type='button' onClick={() => {
                setOpen(true)
            }}>
                <SVGIcons.UploadIcon />
                <p>Select icon</p>
            </button>
            {open && <ConfirmDialog
                isOpen={open}
                onClose={() => {
                    handleClose(false);
                }}
                title={`Select icon`}
                className={'image-selection-popup'}
                description={<><ul className='image-list icon-list'>
                    {Object.keys(availableIcons).map((icon) => (<>
                        <li className={selectedIcon === icon ? 'active' : ''} key={icon} ><button type='button' onClick={() => setSelectedIcon(icon)}>{availableIcons[icon]}</button></li>
                    </>))}
                </ul>
                </>}
                okayButtonText={'Select'}
                onConfirm={handleConfirm}
            />}

        </>
    );
}

IconPicker.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
}

export default IconPicker;