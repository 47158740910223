import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// @mui
import { Button, IconButton, TextField, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { showSnackbar } from '../../components/Notification/Snackbar';
import RichTextEditor from '../../components/rich-text-editor';
import SVGIcons from '../../assets/images/icons/svgIcons';
import FileUploader from '../../components/awsS3ImageUploader/file.uploader';
import './index.scss';
import ApiCaller from '../../services/api/general';
import RichTextViewer from '../../components/rich-text-editor/TextViewer';
import S3ImageViewerWithFallback from '../../components/awsS3ImageViewer/ImgViewerWithFallback';
import BasicDropdown from '../../components/dropdowns/basic.dropdown';
import IconViewer from '../../components/iconPicker/icon-viewer';

// ........................................................................




const AddEditArtGallery = () => {
    const { id = '', page = 'add' } = useParams();
    const navigate = useNavigate();
    const apiCaller = new ApiCaller('gallery');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [isEditEnabled, setIsEditEnabled] = useState(id === undefined || ['add', 'edit'].includes(page));
    const [artGalleryImages, setArtGalleryImages] = useState([]);
    const [artistImage, setArtistImage] = useState('');
    const [tags, setTags] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categoryArr, setCategoryArr] = useState([]);


    const [inputTag, setInputTag] = useState("");

    const [formData, setFormData] = useState({
        artistName: '',
        categories: [],
        description: '',
        images: [],
        extras: {
            dimensions: {
                width: '',
                height: '',
            },
        },
        price: 0,
        shortDescription: '',
        tags: [],
        title: '',
        // subTitle: '',
        status: 'DRAFT',
    });




    const handleAddToArray = () => {
        const newArray = inputTag.split(',').map(item => item.trim()).filter(item => item !== "");

        if (newArray.length > 0) {
            setTags(prevArray => {
                if (Array.isArray(prevArray)) {
                    return [...prevArray, ...newArray];
                }
                return newArray;
            });
            setInputTag("");
        }
    };

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
        return true;
    }


    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (id) {
            getData()
        }
        return () => {
            setFormData({})
        };
        // eslint-disable-next-line
    }, []);

    // get art from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(id)
            .then(({ data }) => {
                if (data) {
                    setFormData(data);
                    setArtGalleryImages(data.images.map(img => img.fileKey));
                    setArtistImage(data.artistImage.fileKey);
                    setTags(data.tags.map(tag => tag));
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }


    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.title) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = (status, refreshData = false) => {
        setIsTouched(true);
        if (isValidForm()) {
            const postData = {
                artistName: formData.artistName,
                artistImage,
                categories: categoryArr,
                description: formData.description,
                extras: { dimensions: formData.extras?.dimensions },
                images: [
                    ...artGalleryImages.map(img => img)
                ],
                price: formData.price,
                shortDescription: formData.shortDescription,
                tags,
                title: formData.title,
                status,
            }
            // Submitting data to backend for registration
            setIsSubmitting(true);
            let message = "Art added successfully";
            let action = saveArt;
            if (id) {
                action = updateArt;
                message = 'Art updated successfully!';
            }
            action(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(message)
                } else {
                    showSnackbar().failure(data.message)
                }
            }).finally(() => {
                setIsSubmitting(false);
                if (id && refreshData) {
                    getData()
                }
            });
        }
    };

    /**
     * Saves a art
     */
    const saveArt = (postData) => apiCaller.postData(postData);

    /**
     * Updates a art using its id
     */
    const updateArt = (postData) => apiCaller.customPatchData(id, postData);

    const deleteArtGalleryImg = (fileKey) => setArtGalleryImages((prv) => prv.filter((img) => img !== fileKey))

    const deleteTag = (element) => setTags((prv) => prv.filter((tag) => tag !== element))


    const addCategory = () => {
        setCategoryArr([...selectedCategories]);
    };

    return (
        <>
            {isLoading && <span className='page-loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading &&

                <Container>
                    <div className='create-news-blk f-wrp'>
                        <div className='btn-wrp navigation-btn-wrp'>
                            <div className='left-btn-box'>
                                <Link to={'/dashboard/art-gallery'} className={`pln-btn back-btn`} ><SVGIcons.BackArrowIcon />Back</Link>
                            </div>
                            {id ? <div className='right-btn-box'>
                                <Button onClick={() => setIsEditEnabled((prv) => !prv)} className={`sqr-btn`} >{isEditEnabled ? 'Cancel' : 'Edit'}</Button>
                                <Button onClick={() => onSubmit(formData.status === 'DRAFT' ? 'PUBLISHED' : 'DRAFT', true)} className={`sqr-btn ${formData?.status}-btn`} >{formData?.status === 'DRAFT' ? 'Publish' : 'Mark as Draft'}</Button>
                            </div> : null}
                        </div>
                        <div className='main-content-box f-wrp'>
                            <div className='sub-header-box f-wrp'>
                                <h3 className='sub-head'><b>{(`${page} Art`)}</b></h3>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-left padding-right-75'>
                                    <div className='each-grid-box standard-form form-full-width panel-form f-wrp'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left no-padding-right'>
                                                <div className='each-TextField'>
                                                    <h4>{("Title")}</h4>
                                                    <TextField
                                                        key={`art_create_form_Key_title`}
                                                        fullWidth
                                                        // label={('art_create_form_question_title')}
                                                        name='title'
                                                        disabled={!isEditEnabled}
                                                        id='art_create_form_question_title'
                                                        value={formData.title}
                                                        type='text'
                                                        isRequired
                                                        placeholder='Title'
                                                        className='custom-textfield'
                                                        helperText={isTouched && !formData.title ? 'Title is required' : ''}
                                                        error={isTouched && !formData.title}
                                                        onChange={(e) => setFormValue('title', e.target.value)}
                                                        useDefaultValidation
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-md-6 col-sm-12 col-xs-12 no-padding-left no-padding-right'>
                                                {/* <div className='each-TextField'>
                                                    <h4>{("Subtitle")}</h4>
                                                    <TextField
                                                        key={`art_create_form_Key_subtitle`}
                                                        fullWidth
                                                        name='subTitle'
                                                        disabled={!isEditEnabled}
                                                        id='art_create_form_question_subtitle'
                                                        value={formData.subTitle}
                                                        type='text'
                                                        isRequired
                                                        placeholder='Subtitle'
                                                        className='custom-textfield'
                                                        helperText={!id && isTouched && !formData.subTitle ? 'Subtitle is required' : ''}
                                                        error={isTouched && !formData.subTitle}
                                                        onChange={(e) => !id ? setFormValue('subTitle', e.target.value) : null}
                                                        useDefaultValidation
                                                    />
                                                </div> */}

                                                <div className='each-TextField'>
                                                    <h4>{("Artist")}</h4>
                                                    <TextField
                                                        key={`art_create_form_Key_artistName`}
                                                        fullWidth
                                                        name='artistName'
                                                        disabled={!isEditEnabled}
                                                        id='art_create_form_question_artistName'
                                                        value={formData.artistName}
                                                        type='text'
                                                        // required
                                                        placeholder='Artist Name'
                                                        className='custom-textfield'
                                                        // helperText={isTouched && !formData.artistName ? 'Artist name is missing' : ''}
                                                        // error={isTouched && !formData.artistName}
                                                        onChange={(e) => setFormValue('artistName', e.target.value)}
                                                        useDefaultValidation
                                                    />
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right padding-left-75'>
                                    <div className='each-grid-box f-wrp'>
                                        <div className='each-TextField'>
                                            <h4>{("Add Image")}</h4>
                                            <div className={`file-upload art-images ${(artGalleryImages?.length > 0) ? 'img-added' : 'no-img'}`}>
                                                {isEditEnabled ? <FileUploader
                                                    key={'art-gallery-images'}
                                                    acceptOnly='image/*'
                                                    uploadCaption={("Select image")}
                                                    // onUploadEnd={(file) => setFormValue('image', file)}
                                                    onUploadEnd={(img) => setArtGalleryImages((prv) => [...prv, img.fileKey])}
                                                /> : null}

                                                {(artGalleryImages?.length > 0) ? <div className='art-preview-box'>
                                                    {artGalleryImages?.map((img, k) => <div key={k} className='each-img-blk'>
                                                        <span style={{ position: 'relative' }}>
                                                            {isEditEnabled ? <IconButton children={<SVGIcons.CloseCrossIcon />} onClick={() => deleteArtGalleryImg(img)} /> : null}
                                                            <S3ImageViewerWithFallback fileKey={img} />
                                                        </span>

                                                    </div>)}
                                                </div> : null}
                                                {/* {(artGalleryImages?.length > 0) ? null : <span style={{ color: 'red' }}>Image is required</span>} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-6 col-sm-12 col-xs-12 padding-right-75 no-padding-left'>
                                    <div className='each-grid-box f-wrp'>
                                        <div className='each-TextField author-image'>
                                            <div className={`file-upload ${artistImage ? 'img-added' : 'no-img'}`}>
                                                {isEditEnabled && !artistImage ? <FileUploader
                                                    acceptOnly='image/*'
                                                    uploadCaption={("Select image")}
                                                    onUploadEnd={(file) => setArtistImage(file.fileKey)}
                                                /> : null}
                                                {artistImage ?
                                                    <span className='uploaded-img artist-image'>
                                                        {isEditEnabled ? <IconButton children={<SVGIcons.CloseCrossIcon />} onClick={() => setArtistImage('')} /> : null}
                                                        <S3ImageViewerWithFallback fileKey={artistImage} />
                                                    </span> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-lg-5 col-md-12 col-sm-12 col-xs-12 padding-left-75 padding-right-75'>
                                    
                                </div> */}
                                <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 no-padding-right padding-left-75'>
                                    <div className='each-TextField'>
                                        <h4>{("Artist Description")}</h4>
                                        <TextField
                                            key={`art_create_form_Key_shortDescription`}
                                            fullWidth
                                            name='shortDescription'
                                            disabled={!isEditEnabled}
                                            id='art_create_form_question_shortDescription'
                                            value={formData.shortDescription}
                                            type='text'
                                            // required
                                            placeholder='Short description'
                                            className='custom-textfield'
                                            // helperText={!formData.shortDescription ? 'Artist Description is missing' : ''}
                                            // error={isTouched && !formData.shortDescription}
                                            onChange={(e) => setFormValue('shortDescription', e.target.value)}
                                            useDefaultValidation
                                        />
                                    </div>
                                </div>

                                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left no-padding-right'>
                                    <div className='each-grid-box each-TextField f-wrp'>
                                        <div className='rich-Text-box'>
                                            <h4>{("Description")}</h4>
                                            {isEditEnabled ? <RichTextEditor defaultValue={formData.description} onChange={(value) => setFormValue('description', JSON.stringify(value))} /> : <>
                                                <RichTextViewer richText={formData.description} />
                                            </>}


                                            {/* {isTouched && !formData.description ? <span style={{ color: 'red' }}>Description is missing</span> : null} */}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 no-padding-left padding-right-75'>
                                    <div className='each-TextField'>
                                        <h4>{("Price")}</h4>
                                        <TextField
                                            key={`art_create_form_Key_price`}
                                            fullWidth
                                            name='price'
                                            disabled={!isEditEnabled}
                                            id='art_create_form_question_price'
                                            value={formData.price}
                                            type='number'
                                            // required
                                            placeholder='Price'
                                            className='custom-textfield'
                                            // helperText={!formData.price ? 'Price is missing' : ''}
                                            // error={isTouched && !formData.price}
                                            onChange={(e) => setFormValue('price', Number(e.target.value))}
                                            useDefaultValidation
                                        />

                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12 no-padding-right padding-left-75'>
                                    <div className='each-TextField'>
                                        <h4>{("Dimensions")}</h4>
                                        <TextField
                                            key={`art_create_form_Key_dimensions_width`}
                                            fullWidth
                                            name='dimensionsWidth'
                                            disabled={!isEditEnabled}
                                            id='art_create_form_question_dimensions_width'
                                            value={formData.extras?.dimensions?.width}
                                            type='text'
                                            // required
                                            placeholder='Width'
                                            className='custom-textfield'
                                            // error={isTouched && !formData.extras?.dimensions?.width}
                                            onChange={(e) => setFormValue('extras', {
                                                ...formData.extras, dimensions: {
                                                    width: e.target.value,
                                                    height: formData.extras?.dimensions?.height
                                                }
                                            })}
                                            useDefaultValidation
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12 no-padding-right padding-left-75'>
                                    <div className='each-TextField'>
                                        <h4>&nbsp;</h4>
                                        <TextField
                                            key={`art_create_form_Key_dimensions_height`}
                                            fullWidth
                                            name='dimensionsHeight'
                                            disabled={!isEditEnabled}
                                            id='art_create_form_question_dimensions_height'
                                            value={formData.extras?.dimensions?.height}
                                            type='text'
                                            // required
                                            placeholder='Height'
                                            className='custom-textfield'
                                            // error={isTouched && !formData.extras?.dimensions?.height}
                                            onChange={(e) => setFormValue('extras', {
                                                ...formData.extras, dimensions: {
                                                    height: e.target.value,
                                                    width: formData.extras?.dimensions?.width
                                                }
                                            })}
                                            useDefaultValidation
                                        />
                                    </div>
                                </div>
                                {/* <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 padding-left-75 padding-right-75'>
                                    <div className='each-TextField'>
                                        <h4>{("Medium")}</h4>
                                        <TextField
                                            key={`art_create_form_Key_medium`}
                                            fullWidth
                                            name='medium'
                                            disabled={!isEditEnabled}
                                            id='art_create_form_question_medium'
                                            value={formData.extras.medium}
                                            type='text'
                                            required
                                            placeholder='Medium'
                                            className='custom-textfield'
                                            error={isTouched && !formData.extras.medium}
                                            onChange={(e) => !id ? setFormValue('medium', e.target.value) : null}
                                            useDefaultValidation
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-12 col-sm-12 col-xs-12 no-padding-right padding-left-75'>
                                    <div className='each-TextField'>
                                        <h4>{("Year")}</h4>
                                        <TextField
                                            key={`art_create_form_Key_year`}
                                            fullWidth
                                            name='year'
                                            disabled={!isEditEnabled}
                                            id='art_create_form_question_year'
                                            value={formData.extras.year}
                                            type='text'
                                            required
                                            placeholder='Year'
                                            className='custom-textfield'
                                            error={isTouched && !formData.extras.year}
                                            onChange={(e) => !id ? setFormValue('year', e.target.value) : null}
                                            useDefaultValidation
                                        />
                                    </div>
                                </div> */}


                                <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 no-padding-left padding-right-75'>
                                    <div className='each-TextField tag-category-box'>
                                        <h4>{("Categories")}</h4>
                                        <div className='flex-box'>


                                            <BasicDropdown
                                                key={'category'}
                                                label="Select Categories"
                                                id='select-categories'
                                                isMultiple
                                                options={[
                                                    {
                                                        "id": 'id1',
                                                        "label": 'category 1',
                                                        "value": 'category 1',
                                                    },
                                                    {
                                                        "id": 'id2',
                                                        "label": 'category 2',
                                                        "value": 'category 2',
                                                    },
                                                    {
                                                        "id": 'id3',
                                                        "label": 'category 3',
                                                        "value": 'category 3',
                                                    },

                                                ]}
                                                onChange={(value) => setSelectedCategories(value)}
                                                value={formData?.categories}
                                            />

                                            <Button onClick={addCategory} className='Add-new-item sqr-btn' >Add</Button>
                                        </div>

                                        {(categoryArr.length > 0) ? <ul>
                                            {categoryArr.map((element, key) => (
                                                <li key={key}>
                                                    {element.icon && <IconViewer icon={element.icon} />} {element}
                                                </li>
                                            ))}

                                        </ul> : null}
                                       

                                            {/* <li>category list <IconButton children={<SVGIcons.CloseCrossIcon />} onClick={() => 'close'} /></li>
                                            <li>category list <IconButton children={<SVGIcons.CloseCrossIcon />} onClick={() => 'close'} /></li>
                                        </ul> */}

                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 padding-left-75 no-padding-right'>
                                    <div className='each-TextField tag-category-box'>
                                        <h4>{("Tags")}</h4>
                                        <div className='flex-box'>
                                            <TextField
                                                key={`art_create_form_Key_tags`}
                                                fullWidth
                                                name='tags'
                                                disabled={!isEditEnabled}
                                                id='art_create_form_question_tags'
                                                value={inputTag}
                                                type='text'
                                                // required
                                                placeholder='tags'
                                                className='custom-textfield'
                                                // error={isTouched && !formData.tags}
                                                onChange={(e) => setInputTag(e.target.value)}
                                                useDefaultValidation
                                            />
                                            <Button onClick={handleAddToArray} className='Add-new-item sqr-btn' >Add</Button>
                                        </div>
                                        {(tags.length > 0) ? <ul>
                                            {tags.map((element, key) => (
                                                <li key={key}>
                                                    {element}
                                                    <IconButton children={<SVGIcons.CloseCrossIcon />} onClick={() => deleteTag(element)} />
                                                </li>
                                            ))}
                                        </ul> : null}
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='btn-wrp navigation-btn-wrp'>
                            <div className='left-btn-box'>
                                <Button onClick={() => navigate('/dashboard/art-gallery')} className='pln-btn'>{("Cancel")}</Button>
                            </div>
                            <div className='right-btn-box'>
                                {isEditEnabled ? <>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        loadingPosition='start'
                                        startIcon={<></>} // To remove Mui warning
                                        fullWidth
                                        disabled={!isValidForm()}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className='sqr-btn brdr-btn'
                                        onClick={() => onSubmit('DRAFT')}>
                                        {("Save as draft")}
                                    </LoadingButton>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        loadingPosition='start'
                                        startIcon={<></>} // To remove Mui warning
                                        fullWidth
                                        disabled={!isValidForm()}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className='sqr-btn'
                                        onClick={() => onSubmit('PUBLISHED')}>
                                        {("Publish")}
                                    </LoadingButton>
                                </> : null}
                            </div>
                        </div>
                    </div>
                </Container>
            }
        </>
    );
}

AddEditArtGallery.propTypes = {}

export default AddEditArtGallery;











