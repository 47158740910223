import React from 'react';
import PropTypes from 'prop-types';
import availableIcons from './available-icons';
import './iconPicker.scss';




const IconViewer = (props) => {
    const {
        icon = ''
    } = props;



    return (
        <span>
            {availableIcons[icon] || <></>}
        </span>
    );
}

IconViewer.propTypes = {
    icon: PropTypes.string,
}

export default IconViewer;